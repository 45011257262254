<template>
  <!-- 项目报备-- 表格区域 -->
  <div class="lists">
    <!-- Element ui -- Form表单 -->
    <template>
      <el-table :data="tableData" style="width: 100%" :row-class-name="tableRowClassName"
        :header-cell-style="{background:'#4888FF'}" height="600">
       <!-- 索引序号 -->
       <el-table-column type="index" width="80" fixed></el-table-column>
        <!-- 编号 -->
        <el-table-column prop="number" label="编号" width="200">
        </el-table-column>
        <!-- 代理商 -->
        <el-table-column prop="agent" label="代理商" width="200">
        </el-table-column>
        <!-- 所属地区 -->
        <el-table-column prop="affiliatedArea" label="所属地区" width="200">
        </el-table-column>
        <!-- 客户名称 -->
        <el-table-column prop="customerName" label="客户名称" width="200">
        </el-table-column>
        <!-- 项目名称 -->
        <el-table-column prop="projectName" label="项目名称" width="200">
        </el-table-column>
        <!-- 负责人 -->
        <el-table-column prop="leader" label="负责人" width="200">
        </el-table-column>
        <!-- 预计时间 -->
        <el-table-column prop="predictedTime" label="预计时间" width="200">
        </el-table-column>
        <!-- 报备状态 -->
        <el-table-column prop="filingStatus" label="报备状态" width="200">
          <!-- 0--已审核 1--待审核 2--报备到期 3--报备失效 -->
          <template slot-scope="scope">
            <div v-if="scope.row.filingStatus == 0 ">
              <img src="../../../assets/image/purchaseManage_slices/eliptical_40.png" alt="" style="margin-right:5px;">已审核
            </div>
            <div v-else-if="scope.row.filingStatus == 1">
              <img src="../../../assets/image/purchaseManage_slices/eliptical_38.png" alt="" style="margin-right:5px;">待审核
            </div>
            <div v-else-if="scope.row.filingStatus == 2">
              <img src="../../../assets/image/purchaseManage_slices/elliptical_50.png" alt="" style="margin:0 5px 0 10px;">报备到期
            </div>
            <div v-else>
              <img src="../../../assets/image/purchaseManage_slices/elliptical_50.png" alt="" style="margin:0 5px 0 10px;">报备失效
            </div>
          </template>
        </el-table-column>
        <!-- 申请时间 -->
        <el-table-column prop="applicationTime" label="申请时间" sortable width="200">
        </el-table-column>
        <!-- 报备有效期 -->
        <el-table-column prop="filingDuration" label="报备有效期" width="200">
        </el-table-column>
        <!-- 操作 -->
        <el-table-column label="详情" fixed="right">
          <template slot-scope="scope">
            <el-link type="primary" @click="jumpToDetail(scope.row)">详情</el-link>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <!-- Element ui--Pagination分页 -->
    <template>
      <el-pagination background @current-change="handleCurrentChange" @size-change="handleSizeChange"
        :current-page="currentPage" :page-sizes="[10]" :page-size="100"
        layout="total, prev, pager, next,sizes,jumper" :total="100">
      </el-pagination>
    </template>
  </div>
</template>

<script>
// 引入vuex的辅助函数
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
export default {
  components: {
  },

  data () {
    return {
      currentPage:1,//当前页数
    };
  },
  computed: {
    ...mapState('projectFiling',['tableData'])
  },
  mounted () {
  },

  methods: {
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 1) {
        return 'success-row';
      }
      return '';
    },
    // 分页的方法
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
    },
    // 跳转到采购详情页
    jumpToDetail(inform){
      this.$router.push({ path: '/filingDetail'})
      // 将需要的传递的信息存储到localStorage
      let informs = JSON.stringify(inform)
      sessionStorage.setItem('informs',informs);
    }
  },
};
</script>

<style  scoped>
.el-table /deep/ .warning-row {
  background: #fff;
}

.el-table /deep/ .success-row {
  background: #f1f3f8;
}
.el-table /deep/ thead {
  font-family: "SHSCN_Regular";
  color: #fff;
  font-weight: normal;
}
.el-table /deep/ .cell {
  font-family: "SHSCN_Regular";
  font-size: 14px;
  font-weight: normal;
}
.lists .el-pagination{
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "Source Han Sans CN-Regular";
}
.lists /deep/ .el-pagination__jump{
  margin-left: 0;
}
.el-table /deep/ .el-table__cell{
  text-align: center;
  padding: 16px 0;
}
</style>