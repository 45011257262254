<template>
  <!-- 项目报备信息 -->
  <div class="purchaseManage">
    <!-- 板块中心区域 -->
    <div class="purchaseManage_mid">
      <div class="purchaseManage_header">
        <!-- 标题 -->
        <div class="title">
          <img src="../../assets/image/EquipmentStorage_slices/rectangle_31.png" alt="">
          <p v-for="(item,index) in $route.meta" :key="index">{{ item }}</p>
        </div>
        <!-- 选项框和按钮 -->
        <div class="option_btn">
          <div class="options">
            <!-- Element ui--Select 选择器 -->
            <!-- 客户信息 -->
            <template>
              <span style="margin:0 0.8vw 0 1.92vw">产品信息</span>
              <el-input v-model="productsKey" placeholder="产品名称/产品编号/产品型号" class="production"></el-input>
            </template >
            <!-- 代理商 -->
            <template>
              <span style="margin:0 0.8vw 0 4vw">代理商</span>
              <el-select v-model="value" placeholder="请选择" style="margin:0 1.45vw 0 0">
                <el-option v-for="item in agentArr" :key="item.id" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </template >
            <!-- 状态 -->
            <template>
              <span style="margin:0 0.8vw 0 0">状态</span>
              <el-select v-model="value1" placeholder="请选择">
                <el-option v-for="item in statusArr" :key="item.id" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </template>
          </div>
        </div>
      </div>

      <!-- 表格区域 -->
      <div class="gateway_form">
          <lists></lists>
      </div>
       <!-- 公司的基本信息 -->
       <div class="firm_inform">
        
      </div>
    </div>
  </div>
</template>

<script>

// 引入vuex的辅助函数
import { mapActions, mapGetters,mapState,mapMutations } from 'vuex';

// 引入列表页
import lists from "./components/lists.vue";
export default {

  components: {
    lists,

  },
  computed: {
   ...mapState('projectFiling',['agentArr','statusArr'])
  },
  data () {
    return {
      productsKey:'',//产品信息
      value:'',//产品类型
      value1:'',//状态
    };
  },

  mounted () {
    
  },

  methods: {

  },
};
</script>

<style  scoped>
.purchaseManage{
 display: flex;
 justify-content: center;
 /* align-items: center; */
}
.purchaseManage .purchaseManage_mid{
  width: 82.5vw;
  height: 85vh;
  margin-top:-5px !important ;
}
/* 头部--开始 */
.purchaseManage_mid .purchaseManage_header{
  width: 100%;
  height: 134px;
  background-color: #fff;
}
.purchaseManage_header .title {
  display: flex;
  align-items: flex-end;
}
.purchaseManage_header .title img {
  width: 6px;
  height: 20px;
  margin: 20px 0 0 20px;
}
.purchaseManage_header .title p {
  font-size: 20px;
  font-family: "SHSCN_Bold";
  margin: 1vw 0 0 0.6vw;
}
/* 选项框和按钮 */
.option_btn {
  width: 100%;
  height: 7.2vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.option_btn /deep/ .el-input{
  width: 206px;
  height: 32px;
}
.options{
  display: flex;
  align-items: center;
  margin-top: 16px;
}
/* 产品信息的输入宽度 */
.production /deep/ .el-input__inner{
  width: 250px;
}
.option_btn /deep/ .el-input__icon{
  line-height: 1.85vh ;
}
.option_btn span{
  font-size: 16px;
  font-family: 'SHSCN_Regular';
}
/* 头部--结束 */

/* 表格区域--开始 */
.purchaseManage_mid .gateway_form {
  width: 100%;
  height: 75vh;
  background-color: #fff;
  margin-top: 1vh;
}
/* 表格区域--结束 */

.lists /deep/ .el-pagination{
  align-items:normal ;
  margin-top: 14px;
  height: 50px !important;
}

/* 屏幕的分辨率大于1920 */
@media screen and (min-width: 1920px) {
.purchaseManage_mid .gateway_form  {
  width: 100%;
  height: 70vh;
  background-color: #fff;
  margin-top: 1vh;
  /* overflow-y:scroll; */
}
/* 公司的基本信息 */
.firm_inform{
  width: 100%;
  height: 40px;
  background-color: #fff;
  margin-top: 21.5px;
}
.lists /deep/ .el-pagination{
  align-items:normal ;
  margin: 14px 20px 0 0;
}
}
@media screen and (max-width: 1680px) {
  /* 公司的基本信息 */
.firm_inform{
  width: 100%;
  height: 30px;
  position: relative;
  top: 116px;
  background-color: #fff;
}
}
</style>